import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-update-content-list'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })
  const routerQuery = router.history.current.query
  const initialSearchParams = Object
    .keys(routerQuery)
    .reduce((previoutValue, currentValue) => {
      if (['page', 'size', 'total'].includes(currentValue)) {
        return previoutValue
      }
      if (currentValue === null || currentValue === '') {
        return previoutValue
      }
      if (typeof routerQuery[currentValue] === 'string') {
        return {
          ...previoutValue,
          [currentValue]: [routerQuery[currentValue]],
        }
      }
      return {
        ...previoutValue,
        [currentValue]: routerQuery[currentValue],
      }
    },
    {})
  const searchParams = ref({
    page: parseInt(routerQuery.page, 10) || 1,
    size: parseInt(routerQuery.size, 10) || 20,
    total: parseInt(routerQuery.total, 10) || 0,
    ...initialSearchParams,
  })

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '버전',
      value: 'VERSION',
      paramName: 'version',
    },
  ]

  const tableColumns = [
    { key: 'idx' },
    { key: 'type' },
    { key: 'version' },
    { key: 'title' },
    { key: 'state' },
    { key: 'created_at', formatter: val => moment(val).format('YYYY-MM-DD HH:mm:ss') },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const currentPage = computed(() => parseInt(searchParams.value.page, 10))
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', 'Error fetching users list')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const search = params => {
    Object.assign(searchParams.value, { ...params, page: 1 })
    fetchItems()
  }

  const changePage = params => {
    Object.assign(searchParams.value, params)
    fetchItems()
  }

  const versionSwitchToActive = ref()
  const inputVersionSwitchToActive = value => {
    versionSwitchToActive.value = value
  }
  const isValidSwitchToActive = computed(() => Boolean(versionSwitchToActive.value))
  const visibleSwitchToActiveModal = ref(false)
  const turnOnSwitchToActiveModal = () => {
    visibleSwitchToActiveModal.value = true
  }
  const turnOffSwitchToActiveModal = () => {
    visibleSwitchToActiveModal.value = false
  }
  const switchVersion = () => {
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/switchVersion`, {
        version: versionSwitchToActive.value,
      })
      .then(() => {
        makeToast('primary', '버전 변경에 성공했습니다')
        fetchItems()
      })
      .catch(() => {
        makeToast('danger', '버전 변경에 실패했습니다')
      })
      .finally(() => {
        turnOffSwitchToActiveModal()
      })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })
  return {
    makeToast,

    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    versionSwitchToActive,
    inputVersionSwitchToActive,
    isValidSwitchToActive,
    visibleSwitchToActiveModal,
    turnOnSwitchToActiveModal,
    turnOffSwitchToActiveModal,
    switchVersion,
  }
}
