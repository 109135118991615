<template>
  <BModal
    :visible="visible"
    centered
    title="버전 변경"
    @hidden="$emit('hidden')"
  >
    <BFormGroup
      label="활성화 할 버전"
    >
      <BFormInput
        :value="version"
        @input="$emit('inputVersion', $event)"
      />
    </BFormGroup>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <BButton
          variant="outline-secondary"
          class="mr-1"
          @click="$emit('hidden')"
        >
          취소
        </BButton>
        <BButton
          variant="primary"
          :disabled="!isValid"
          @click="$emit('ok')"
        >
          확인
        </BButton>
      </div>
    </template>

  </BModal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    version: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
