<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #buttons>
        <b-button
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'update-content-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>

        <b-button
          variant="outline-info"
          class="mr-1"
          @click="turnOnSwitchToActiveModal"
        >
          <span class="align-middle">ACTIVE 버전 전환</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <b-link
          :to="{ name: 'update-content-detail', params: { idx: data.value } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.value }}
        </b-link>
      </template>
    </tagby-list>

    <SwitchToActiveModal
      :visible="visibleSwitchToActiveModal"
      :version="versionSwitchToActive"
      :isValid="isValidSwitchToActive"
      @inputVersion="inputVersionSwitchToActive"
      @hidden="turnOffSwitchToActiveModal"
      @ok="switchVersion"
    />
  </div>
</template>

<script>
import {
  BMedia,
  BLink,
  BAvatar,
  BAvatarGroup,
  BButton,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import SwitchToActiveModal from './components/SwitchToActiveModal.vue'

export default {
  components: {
    BMedia,
    BLink,
    BAvatar,
    BAvatarGroup,
    BButton,
    TagbyFilter,
    TagbyList,
    SwitchToActiveModal,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
